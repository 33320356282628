<template>
	<Layout>
		<!-- start page title -->
		<div class="row align-items-center">
			<div class="col-sm-6">
				<div class="page-title-box">
					<h4 class="font-size-18">Dashboard</h4>
				</div>
			</div>
		</div>
		<!-- end page title -->
	</Layout>
</template>

<script>
import Layout from "../layouts/main";

export default {
	page: {
		title: "Dashboard"
	},
	components: {
		Layout
	},
	data() {
		return {
		}
	}
};
</script>
