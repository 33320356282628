<template>
	<header id="page-topbar">
		<div class="navbar-header">
			<div class="d-flex">
				<!-- LOGO -->
				<div class="navbar-brand-box">
					<a href="/" class="logo logo-dark">
						<span class="logo-sm">
							<img src="@/assets/images/logo-sm.png" alt height="22" />
						</span>
						<span class="logo-lg">
							<img src="@/assets/images/logo-dark.png" alt height="40" />
						</span>
					</a>

					<a href="/" class="logo logo-light">
						<span class="logo-sm">
							<img src="@/assets/images/logo-sm.png" alt height="22" />
						</span>
						<span class="logo-lg">
							<img src="@/assets/images/logo-light.png" alt height="18" />
						</span>
					</a>
				</div>

				<button
					type="button"
					class="btn btn-sm mr-2 font-size-24 d-lg-none header-item"
					@click="toggleMenu"
				>
					<i class="mdi mdi-menu"></i>
				</button>
			</div>
		</div>
	</header>
</template>

<script>
/**
 * Horizontal-topbar component
 */
export default {
	methods: {
		toggleMenu() {
			let element = document.getElementById("topnav-menu-content");
			element.classList.toggle("show");
		}
	}
};
</script>
