// eslint-disable-next-line
import { mapState, mapGetters, mapActions } from 'vuex'

export const layoutComputed = {
	...mapState('layout', {
		layoutType: (state) => state.layoutType,
		layoutWidth: (state) => state.layoutWidth,
	}),
}

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth'])
